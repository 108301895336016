// extracted by mini-css-extract-plugin
export var fadeIn = "c_h";
export var featuresSection = "c_m";
export var featuresSectionImage = "c_n";
export var headSection = "c_f";
export var headSectionImage = "c_j";
export var headSectionLogo = "c_g";
export var icons = "c_q";
export var relatedProductsSection = "c_p";
export var textSection = "c_k";
export var textSectionVideo = "c_l";